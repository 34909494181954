<template>
  <div class="abstract">
    <div class="_title" v-if="title">报告提交</div>
    <div class="_content" :class="className">
      <v-card flat class="mt-4">
        <v-card-text class="pa-0">
          <v-form ref="form" v-model="valid" lazy-validation :style="formStyle">
            <div class="d-flex align-center">
              <div class="label mr-3" style="width: 80px; text-align: end">
                摘要主题：
              </div>
              <p class="ma-0">您的摘要属于以下哪个主题（必选）？</p>
            </div>

            <div class="d-flex align-center">
              <div class="label mr-6" style="width: 100px; text-align: end">
                按领域划分:
              </div>
              <v-radio-group v-model="abstract.theme">
                <v-radio label="分子标记辅助选择" value="1"></v-radio>
                <v-radio label="转基因" value="2"></v-radio>
                <v-radio label="基因编辑" value="3"></v-radio>
                <v-radio label="设计育种" value="4"></v-radio>
                <v-radio label="基因组选择" value="5"></v-radio>
                <v-radio label="大数据和智慧育种" value="6"></v-radio>
                <v-radio label="技术平台" value="7"></v-radio>
                <v-radio label="其他" value="8"></v-radio>
              </v-radio-group>
            </div>
            <div class="d-flex align-center">
              <div class="label mr-6" style="width: 100px; text-align: end">
                按物种划分
              </div>
              <v-radio-group v-model="abstract.theme">
                <v-radio label="大田作物-禾谷类" value="9"></v-radio>
                <v-radio label="大田作物-其它" value="10"></v-radio>
                <v-radio label="果树" value="11"></v-radio>
                <v-radio label="蔬菜" value="12"></v-radio>
                <v-radio label="林木茶叶" value="13"></v-radio>
                <v-radio label="技术方法类（与物种无关）" value="14"></v-radio>
              </v-radio-group>
            </div>

            <div class="d-flex align-center">
              <div class="label mr-3" style="width: 80px; text-align: end">
                摘要文件：
              </div>
              <v-file-input
                :value="file1"
                label="文件上传"
                @change="fileChange"
                accept=".doc,.docx,.ppt"
              ></v-file-input>
            </div>
            <p style="text-align: center">
              (.doc, .docx)摘要模板请至“下载中心”。
            </p>
            <!-- <div class="d-flex align-center">
              <div class="label mr-3" style="width: 80px; text-align: end">
                墙报文件：
              </div>
              <v-file-input
                :value="file2"
                @change="fileChange2"
                accept=".doc,.docx,.ppt,.pdf,.pptx"
                label="文件上传"
              ></v-file-input>
            </div>
            <p style="text-align: center">
              (.ppt, pptx)墙报模板请至“下载中心”。
            </p> -->
          </v-form>

          <v-btn
            style="min-width: 0 !important; width: 300px; margin: 0 auto"
            :disabled="!valid"
            color="success"
            block
            class="mt-4"
            @click="abstractAction"
          >
            提交
          </v-btn>
        </v-card-text>
      </v-card>
    </div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-dialog v-model="dialog" width="300">
      <v-card class="saveDialog">
        <div class="saveDialog-desc">
          提交成功
        </div>

        <v-divider></v-divider>
        <v-btn
          class="saveDialog-btn"
          color="primary"
          text
          @click="dialog = false"
        >
          确定
        </v-btn>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      valid: true,
      file1: null,
      file2: null,
      overlay: false,
      dialog: false,
      abstract: {
        theme: '1',
        abstractPath: null,
        wallPath: null,
      },
    };
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    formStyle() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 'width: 100%; ';
        case 'sm':
          return 'width: 100%; ';
        case 'md':
          return 'width: 55%; ';
        case 'lg':
          return 'width: 55%; ';
        case 'xl':
          return 'width: 55%; ';
      }
    },
  },
  props: {
    title: {
      type: Boolean,
      default: true,
    },
    className: {
      type: String,
      default: 'tab-item',
    },
  },
  methods: {
    clear() {
      this.$refs.form.reset();
    },

    getAbstract() {
      this.$request({
        url: '/conference/submit/abstract',
        method: 'get',
        data: this.abstract,
      }).then(() => {
        this.$message.tips('保存成功');
      });
    },
    fileChange(file) {
      if (file) {
        this.overlay = true;
        let form = new FormData();
        form.append('wordFile', file);
        this.$request({
          url: '/file/upload/doc',
          method: 'post',
          data: form,
        })
          .then((res) => {
            this.overlay = false;
            this.$message.tips('上传成功');
            this.abstract.abstractPath = res.data;
          })
          .catch(() => {
            this.overlay = false;
          });
      } else {
        this.abstract.abstractPath = '';
      }
    },
    fileChange2(file) {
      if (file) {
        this.overlay = true;
        let form = new FormData();
        form.append('wordFile', file);
        this.$request({
          url: '/file/upload/doc',
          method: 'post',
          data: form,
        })
          .then((res) => {
            this.$message.tips('上传成功');
            this.overlay = false;
            this.abstract.wallPath = res.data;
          })
          .catch(() => {
            this.overlay = false;
          });
      } else {
        this.abstract.wallPath = '';
      }
    },
    abstractAction() {
      this.$request({
        url: '/conference/submit/abstract',
        method: 'post',
        data: this.abstract,
      }).then(() => {
        // alert('保存成功')
        this.dialog = true;
        // this.$message.tips('保存成功');
      });
    },
  },
};
</script>

<style lang="less" scoped>
.saveDialog {
  text-align: center;
  .saveDialog-desc {
    height: 100px;
    font-size: 20px;
    line-height: 100px;
  }
  .saveDialog-btn {
    margin: 0 auto;
  }
}
.abstract {
  padding-bottom: 100px;
  ._content {
    .v-card__text {
      font-size: 15px !important;
      line-height: 25px;
    }
  }
  .item-boeder {
    border: 1px solid #c4d5e0;
  }
  .form-title {
    font-size: 18px;
    background: #efefef;
    color: #3b86aa;
    height: 40px;
    line-height: 40px;
    padding-left: 40px;
    border-radius: 10px;
    font-weight: bold;
  }
  ._title {
    text-align: center;
    font-size: 25px;
    font-weight: bold;
  }
  .desc-title {
    color: #666666;
  }
  .bold {
    font-weight: bold;
  }
  ._red {
    color: #e53333;
  }
}
</style>
